<template>
  <iframe class="i-frame full-flex" :class="{ 'no-pointer-events': noPointerEvents }" ref="i-frame"></iframe>
</template>

<script>
export default {
  name: 'IFrame',
  data: () => ({
    initContent: null
  }),
  props: {
    noPointerEvents: {
      type: Boolean,
      default: false
    },
    content: Object
  },
  watch: {
    content() {
      this.init()
    },
    noPointerEvents(newVal, oldVal) {
      if (!newVal && oldVal) {
        // Chrome Bug which keeps hiding the scrollbar when pointer-event is removed from CSS requires reinit
        this.init(true)
      }
    }
  },
  methods: {
    init(force) {
      if (!force && (this.content === this.initContent)) {
        return
      }

      this.$el.srcdoc = ''
      this.initContent = this.content

      if (!this.content) {
        this.$el.srcdoc = ''
        return
      }

      try {
        if (typeof this.content === 'string') {
          const tempDiv = document.createElement('div')
          tempDiv.innerHTML = this.content
          this.$el.srcdoc = tempDiv.innerHTML
        } else if (this.content instanceof Element || this.content instanceof Document || 'innerHTML' in this.content) {
          this.$el.srcdoc = this.content.innerHTML
        } else {
          console.warn('IFrame content must be a string or DOM element')
          this.$el.srcdoc = ''
        }
      } catch (error) {
        console.error('Error setting iframe content:', error)
        this.$el.srcdoc = ''
      }
    }
  },
  mounted() {
    this.init()
  }
}
</script>

<style lang="scss" scoped>
  .i-frame {
    border: 1px solid #eee;
  }
  // hanlde pointer events not in iFrame but in the surrounding html 
  .no-pointer-events {
    pointer-events: none;
  }

  .full-flex {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }
</style>
