<template>
  <div class="vue-composer full-flex">
    <v-dialog v-model="showPDFInfo" transition="dialog-bottom-transition" max-width="600">
      <v-card flat>
        <v-card-title class="text-h6 white--text primary">
          Info
        </v-card-title>
        <v-card-text style="padding: 20px;">
          <div>Fügt CSS Klassen für Seitenwechsel im PDF hinzu.</div>
          <code>
            &lt;div class="page"&gt;
              &lt;!-- Seiteninhalt --&gt; 
            &lt;/div&gt;
          </code>
          <div>oder</div>
          <code>
            &lt;div class="page-break" /&gt;
          </code>
        </v-card-text>

        <v-card-actions dense style="border-top: 1px solid rgb(233, 233, 233);"> 
          <v-spacer></v-spacer>
          <v-btn text @click="showPDFInfo = false">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <div class="tools" style="display: flex; flex: 1 1 auto;">
      <v-btn icon @click="downloadPdf">
        <v-icon color="secondary">mdi-file-pdf-box</v-icon>
      </v-btn>
      <v-switch
        style="margin: 0 0 0 10px;"
        v-model="preview"
        :label="$t('tooltips.SHOW_PREVIEW')"
      ></v-switch>
    </div>
  
    <div v-show="!preview" class="full-flex">
      <v-card class="full-flex" elevation="0">
        <v-tabs v-model="activeTab" style="display: flex; flex: 0 0 auto; align-items: flex-end; border-bottom: 1px solid rgb(233, 233, 233);">
          <v-tab v-for="aceConfig in aceConfigs" v-bind:key="aceConfig.name">{{ aceConfig.name }}</v-tab>
        </v-tabs>
        
        <v-card-text class="full-flex" style="padding: 0;">
          <v-tabs-items v-model="activeTab">
            <v-tab-item v-for="aceConfig in aceConfigs" v-bind:key="aceConfig.name" class="tab-item">
              <div v-if="aceConfig.name == 'configuration'" class="ace-container full-flex">
                <div style="margin: 20px; padding: 20px; background: #fff; border: 1px solid #eee;">
                  <h3>PDF</h3>
                  <div style="margin: 5px 0 10px; border: 1px solid #eee; padding: 5px; position: relative;">
                    <v-checkbox v-model="addPdfStyle" label="ADD PDF STYLE"></v-checkbox>
                    <v-btn icon @click="showPDFInfo = true" style="position: absolute; top: 3px; right: 3px;">
                      <v-icon color="secondary">mdi-information</v-icon>
                    </v-btn>
                  </div>

                  <div style="margin: 5px 0 10px; border: 1px solid #eee; padding: 5px; position: relative;">
                    <h4 style="margin: 0 0 5px;">PDF Config (<a href="https://pptr.dev" target="_blank">Puppeteer</a> <a href="https://pptr.dev/api/puppeteer.pdfoptions" target="_blank">Options</a>)</h4>
                    <div style="height: 200px; width: 400px; display: flex; border: 1px solid #eee;">
                      <ace-editor :config="aceConfig.pdfAceConfig" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="ace-container">
                <ace-editor :config="aceConfig" />
              </div>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </div>
    <div v-if="preview" class="full-flex">
      <vue-composer-preview :config="newConfig"></vue-composer-preview>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import dateFormat from 'dateformat'

import api from '@/services/api'

export default {
  name: 'vue-composer',
  computed: {
    addPdfStyle: {
      get () {
        return this.newConfig ? this.newConfig.config.addPdfStyle : true
      },
      set (addPdfStyle) {
        this.newConfig.config.addPdfStyle = addPdfStyle
        this.triggerChange()
      }
    }
  },
  data: () => ({
    aceConfigs: [],
    activeTab: 'template',
    newConfig: null,
    ready: false,
    showPDFInfo: false
  }),
  props: {
    config: Object,
    preview: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    config(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.init(newVal)
      }
    }
  },
  methods: {
    init(config) {
      this.destroy()

      if (!config || !this.ready) {
        return
      }

      config = this.newConfig = Object.assign({
        template: '',
        global: null,
        dataset: null,
        options: null,
        translations: null,
        config: {
          addPdfStyle: true,
          pdfConfig: {}
        }
      }, config)
      
      this.aceConfigs = [
        {
          autoResize: true,
          beautify: false,
          name: 'template',
          onChange: value => {
            config.template = value
            this.triggerChange()
          },
          type: 'html',
          value: config.template
        },
        {
          autoResize: true,
          beautify: true,
          name: 'dataset',
          onChange: value => {
            try {
              config.dataset = JSON.parse(value)
              this.triggerChange()
            } catch(e) {}
          },
          type: 'json',
          value: JSON.stringify(config.dataset)
        },
        {
          autoResize: true,
          beautify: true,
          name: 'translations',
          onChange: value => {
            try {
              config.translations = JSON.parse(value)
              this.triggerChange()
            } catch(e) {}
          },
          type: 'json',
          value: JSON.stringify(config.translations)
        },
        {
          autoResize: true,
          beautify: true,
          name: 'global',
          onChange: value => {
            try {
              config.global = JSON.parse(value)
              this.triggerChange()
            } catch(e) {}
          },
          type: 'json',
          value: JSON.stringify(config.global)
        },
        {
          name: 'configuration',
          pdfAceConfig: {
            autoResize: false,
            beautify: true,
            name: 'pdfAceConfig',
            onChange: value => {
              try {
                config.config.pdfConfig = JSON.parse(value)
                this.triggerChange()
              } catch(e) {}
            },
            type: 'json',
            value: JSON.stringify(config.config.pdfConfig || {})
          }
        }
        // disabled because of eval() security vulnerability

        // keep code in case options should be used
        // {
        //   autoResize: true,
        //   beautify: false,
        //   name: 'options',
        //   onChange: value => {
        //     config.options = value
        //     this.triggerChange()
        //   },
        //   type: 'javascript',
        //   value: config.options
        // }
      ]    
    },
    destroy() {
      this.newConfig = null
      this.aceConfigs = []
    },
    async downloadPdf() {
      const response = await api.call('getVueTemplatePdf', {
        data: this.newConfig
      })

      const arr = response.data

      const int8Array = new Int8Array(arr)
      const blob = new Blob([int8Array])
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')

      a.href = url
      a.download = `previewPdf${dateFormat(Date.now(), "yyyy-mm-dd'T'HH:MM:ss")}.pdf`
      
      document.body.appendChild(a)
      
      a.click()
      
      document.body.removeChild(a)
      
      URL.revokeObjectURL(url)
    },
    triggerChange() {
      this.$emit('change', this.newConfig) 
    }
  },
  mounted () {
    this.ready = true

    this.init(this.config || {
      template: ''
    })
  },

  beforeDestroy() {
    this.destroy()
  }
}
</script>

<style lang="scss" scoped>
  .vue-composer {
    position: relative;
  }

  .tools {
    position: absolute;
    top: 7px;
    right: 15px;
    z-index: 1000;
    margin: 0;
  }

  :deep {
    .ace-container {
      overflow: auto;
      display: flex;
      flex: 1 0 0;
      background: #f0f0f0;
      border-top: 1px solid rgb(233, 233, 233);
    }
  }

  .full-flex {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
  }

  .tab-item {
    height: 300px;
  }

  $tabsSidebarWidth: 370px;

  // make tab items stretch to full height
  :deep {
    .data-entry-dialog  {
      overflow-y: visible;
    }

    .sv_body {
      padding: 0 !important;
      border: none !important;
    } 

    .v_window, .v-window__container, .v-window-item, .v-item-group {
      flex: 1 1 0;
      min-height: 0;
      display: flex;
    }
  }


  .tabs-sidebar {
    display: flex;
    flex: 0 0 auto;
    box-shadow: none;
    border-left: 1px solid rgb(233, 233, 233);
  }
</style>