<template>
  <div>
    <v-dialog
      v-model="dialogOpen"
      persistent
      scrollable
      fullscreen
    >
      <v-card v-if="dialogOpen" style="display: flex; flex-direction: column;">
        <v-card-title class="text-h6 white--text primary">
          <div>HTML Composer</div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pa-0" style="display: flex; flex: 1 1 0; padding: 0;">
          <vue-composer :config="config" @change="change" />
        </v-card-text>
        <v-card-actions style="border-top: 1px solid rgb(233, 233, 233);">
          <!--<v-menu>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="sendEmail">
                <v-list-item-content>Send Test Email</v-list-item-content>
                <v-list-item-action>
                  <v-btn icon>
                    <v-icon>mdi-email-arrow-right-outline</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>-->
          
          <v-spacer></v-spacer>
          
          <v-btn text @click="cancel">{{ $t('actions.cancel') }}</v-btn>
          <v-btn color="primary" @click="save">{{ $t('actions.ok') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn depressed color="primary" @click="openDialog" :disabled="question.isReadOnly">
      {{ 'HTML Composer' }}
    </v-btn>
  </div>
</template>

<script>
import VueComposer from '../VueComposer.vue'

export default {
  components: { VueComposer },
  name: 'surveyjs-creator-form',
  data: () => ({
    changedConfig: null,
    config: null,
    dialogOpen: false
  }),
  props: {
    question: Object
  },
  async beforeMount() {
    this.config = this.question.value
  },
  methods: {
    cancel() {
      this.dialogOpen = false
    },

    change(config) {
      this.changedConfig = config
    },

    help() {
      console.log('help')
    },

    async openDialog() {
      this.dialogOpen = true
    },

    save() {
      this.config = this.changedConfig
      this.changedConfig = null

      // assign has no affect for simple (not deep) equality so a version number is added to force changes
      this.config.__version = this.config.__version ? this.config.__version + 1 : 1
  
      this.question.value = this.config
      this.dialogOpen = false
    },

    sendEmail() {
      console.log('send email')
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
