import jsonFormat from 'json-format'

import { getLocale } from '@/i18n'
import { discardElement } from 'highcharts'

// function to act as a class
function LinkCellRenderer() {}

const localeValue = function(stringOrObject, locale) {
  let value

  if (_.isObject(stringOrObject)) {
    value = stringOrObject[locale || getLocale()]
    value = value === undefined ? stringOrObject['org'] : value
  } else {
    value = stringOrObject
  }

  if (typeof value == 'string') {
    // Remove all line breaks. This is mainly done for verbatim texts (see #202)
    value = value.replace(/(\r\n|\n|\r)/gm, '')
  }

  return value
}

// gets called once before the renderer is used
LinkCellRenderer.prototype.init = function(params) {
  if (params.value) {
    this.eGui = document.createElement('div')
    this.eGui.innerHTML = params.value
    
    let aElems = this.eGui.getElementsByTagName('a')
    for (let aElem of aElems) {
      // open link in new tab/window as default
      if (!aElem.getAttribute('target')) {
        aElem.setAttribute('target', '_blank')
        aElem.onclick = function(event) {
          // if link is clicked disable default behaviour (do not open details box)
          event.stopPropagation()
        } 
      }
    }
  }
}

// gets called once when grid ready to insert the element
LinkCellRenderer.prototype.getGui = function() {
  return this.eGui
}

// function to act as a class
function TextCellRenderer() {}

// gets called once before the renderer is used
TextCellRenderer.prototype.init = function(params) {
  if (params.value) {
    let value

    if (_.isObject(params.value)) {
      value = params.value[getLocale()]
      value = value === undefined ? params.value['org'] : value
    } else {
      value = params.value
    }

    let span = document.createElement('span')
    span.setAttribute('title', params.value)
    span.textContent = value

    this.eGui = span //document.createTextNode(value)
  }
}

// gets called once when grid ready to insert the element
TextCellRenderer.prototype.getGui = function() {
  return this.eGui
}

function VerbatimCellRenderer() {}

// gets called once before the renderer is used
VerbatimCellRenderer.prototype.init = function(params) {
  if (params.value) {
    let value

    if (_.isObject(params.value)) {
      value = params.value[getLocale()]
      value = value === undefined ? params.value['org'] : value
    } else {
      value = params.value
    }

    this.eGui = document.createTextNode(value)
  }
}

// gets called once when grid ready to insert the element
VerbatimCellRenderer.prototype.getGui = function() {
  return this.eGui
}

function JsonCellRenderer() {}

// gets called once before the renderer is used
JsonCellRenderer.prototype.init = function(params) {
  if (params.value) {
    let div = document.createElement("div")

    div.style.overflow = 'hidden'
    div.style.width = '400px'
    div.style.textOverflow = 'ellipsis'

    div.append(jsonFormat(params.value, {
      type: 'space',
      size: 2
    }))
    this.eGui =  div
  }
}

// gets called once when grid ready to insert the element
JsonCellRenderer.prototype.getGui = function() {
  return this.eGui
}

export default {
  renderExport(cellRenderer, value, locale) {
    console.assert(locale == null || locale.indexOf('-') === -1)
    if (value) {
      if (cellRenderer === TextCellRenderer || cellRenderer === VerbatimCellRenderer) {
        return localeValue(value, locale)
      } else if (cellRenderer === LinkCellRenderer) {
        value = localeValue(value, locale)
        let div = document.createElement('div')
        div.innerHTML = value
        
        let linkTexts = []

        let aElems = div.getElementsByTagName('a')
        if (aElems.length > 0) {
          for (let aElem of aElems) {
            linkTexts.push(aElem.getAttribute('href'))
          }
          return linkTexts.join(' ')
        } else {
          return value
        }
      } else if (cellRenderer === JsonCellRenderer) {
        return JSON.stringify(value)
      }
    }
    return value
  },

  JsonCellRenderer: JsonCellRenderer,

  LinkCellRenderer: LinkCellRenderer,

  TextCellRenderer: TextCellRenderer,

  VerbatimCellRenderer: VerbatimCellRenderer
}