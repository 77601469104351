import { StylesManager, FunctionFactory, setLicenseKey, surveyLocalization } from "survey-core"
import store from '@/store'
import _ from 'lodash'

import VariableModel from '@/models/variable'

const SURVEY_OPTIONS = {
  hideContinue: false
}

const surveyJsUtil = {
  init(options) {
    options = Object.assign({
    }, options || {})

    surveyJsUtil.applyStyle(options)
    surveyJsUtil.registerFunctions()
    setLicenseKey(
      "ZmMxYzMzNGItZWYwZi00ZWMzLTk1OTEtZThjNjNlY2YyNWM0OzE9MjAyNS0wNi0yNiwyPTIwMjUtMDYtMjY="
    )

    // add more languages than the default languanges
    surveyLocalization.localeNames["cs"] = "Czech"
  },

  addTextMarkdown(survey) {
    // allow html in the survey title
    survey.onTextMarkdown.add((survey, options) => {
      let str = (options.text || '').trim()

      // apply html if there is an opening and closing tag in the string
      if (str.match(/^\s*<.+>[\s\S]*<\/.+>\s*$/)) {
        options.html = str
      }
    })
  },

  applyStyle(options) {
    options = Object.assign({
      theme: 'defaultV2' // 'legacy', "defaultV2", "modern"
    }, options || {})

    let defaultThemeColors = StylesManager.ThemeColors["default"]

    defaultThemeColors["$main-color"] = "#666"
    defaultThemeColors["$main-hover-color"] = "#666"
    defaultThemeColors["$text-color"] = "rgba(0, 0, 0, 0.87)"
    defaultThemeColors["$header-color"] = "rgba(0, 0, 0, 0.87)"

    switch (options.theme) {
      case "legacy":
        StylesManager.applyTheme()
        break
      case "modern":
        StylesManager.applyTheme("modern")
        break
      default:
        StylesManager.applyTheme("defaultV2")
        break
    }
  },

  computeElementDefinition(variable, extendElementDefintion) {
    let config = Object.assign(
      {
        rendered: false
      },
      (variable && variable._config) || {},
      (extendElementDefintion && extendElementDefintion._config) || {}
    )

    let elementDefinition = {
      name: variable.type === 'verbatim_n' ? variable.name + '.fractions' : variable.name,
      title: variable.label
    }


    if (variable.type == 'verbatim_n') {
      let codingvars = variable.codingvars

      if (!codingvars) {
        return
      }

      let templateElements = []

      if (extendElementDefintion) {
        let type = extendElementDefintion.type || "paneldynamic"
        
        switch (type) {
          case "paneldynamic":
            let templateElements = extendElementDefintion.templateElements || []
            let extendedTemplateElements = []

            templateElements.forEach(templateElement => {
              let extendedElementDefinition = surveyJsUtil.extendElementDefintion(templateElement, codingvars)
              extendedElementDefinition && extendedTemplateElements.push(extendedElementDefinition)
            })

            extendElementDefintion.templateElements = extendedTemplateElements

            break
          case "matrixdynamic":
            // TODO: handle matrixdynamic
            break
          default:
            throw(new Error(`unsupported type "${type}"`))
        }

        Object.assign(elementDefinition, {
          type: type
        })
      } else {
        codingvars.forEach(codingvar => {
          let computedElementDefinition = surveyJsUtil.computeElementDefinition(codingvar)
          computedElementDefinition && templateElements.push(computedElementDefinition)
        })

        Object.assign(elementDefinition, {
          type: "paneldynamic",
          renderMode: "progressTop",
          templateElements: templateElements
        })
      }
    } else {
      let surveyTypeDef = VariableModel.wonennTypeToSurveyType(variable.type, variable.subtype)

      elementDefinition.type = surveyTypeDef.surveyType

      let choices = variable.choices || variable.categories

      if (choices) {
        elementDefinition.choices = choices
      }

      if (surveyTypeDef.surveySubtype) {
        elementDefinition.subtype = surveyTypeDef.surveySubtype
      }

      if (['html', 'link'].indexOf(variable.type) != -1 && config.rendered) {
        // TODO: find a more generic approach
        elementDefinition.type = 'html-rendered'
      }
    }

    if (extendElementDefintion) {
      Object.assign(elementDefinition, extendElementDefintion)
    }

    return elementDefinition      
  },

  extendElementDefintion(elementDefinition, variables) {
    let variableName = null
    let extendElementDefintion = null
    let skipVariableSurveyJsElementDefinition = false
    
    if (_.isString(elementDefinition)) {
      variableName = elementDefinition
    } else if (_.isArray(elementDefinition)) {
      [variableName, extendElementDefintion, skipVariableSurveyJsElementDefinition] = elementDefinition
    } else if (elementDefinition.variableName) {
      variableName = elementDefinition.variableName
  
      extendElementDefintion = Object.assign({}, elementDefinition)
  
      delete extendElementDefintion.variableName
    } else if (elementDefinition.name) {
      variableName = elementDefinition.name

      extendElementDefintion = Object.assign({}, elementDefinition)
    }
  
    skipVariableSurveyJsElementDefinition = skipVariableSurveyJsElementDefinition === true
  
    if (variableName) {
      let variable = variables.find(variable => variable.name === variableName)
      
      if (!variable) {
        return elementDefinition
      }
  
      if (variable.surveyJs && !skipVariableSurveyJsElementDefinition) {
        let variableElementDefinition
        let skipComputedElementDefinition = false
  
        if (_.isArray(variable.surveyJs)) {
          [variableElementDefinition, skipComputedElementDefinition] = variable.surveyJs
        } else {
          variableElementDefinition = variable.surveyJs
        }
  
        elementDefinition = Object.assign(skipComputedElementDefinition ? {} : surveyJsUtil.computeElementDefinition(variable, extendElementDefintion), variableElementDefinition)
      } else {
        elementDefinition = surveyJsUtil.computeElementDefinition(variable, extendElementDefintion)
      }
    }
  
    return elementDefinition
  },
  
  extendSurveyJsDefinition(surveyJsDefinition, variables) {
    if (_.isArray(surveyJsDefinition)) {
      surveyJsDefinition.forEach(x => surveyJsUtil.extendSurveyJsDefinition(x, variables))
    } else if (_.isObject(surveyJsDefinition)) { 
      if (surveyJsDefinition.elements) {
        let elements = []
  
        surveyJsDefinition.elements.forEach(element => {
          let extendedElementDefinition = surveyJsUtil.extendElementDefintion(element, variables)
          extendedElementDefinition && elements.push(extendedElementDefinition)
        })
  
        surveyJsDefinition.elements = elements
      }
  
      for (let key in surveyJsDefinition) {
        surveyJsUtil.extendSurveyJsDefinition(surveyJsDefinition[key], variables)
      }
    }
  },

  getSurveyOption(survey, optionName) {
    return survey && Object.assign({}, SURVEY_OPTIONS, survey.__OPTIONS || {})[optionName]
  },

  registerFunctions() {
    const lookup = async function(params) {
      let collectionName = params[0]
      let sourceKey = params[1]
      let value = params[2]
      let targetKey = params[3]

      let surveyJsMapping = await store.dispatch('getSurveyJsMapping', {
        collectionName: collectionName
      })

      let entry = surveyJsMapping.find(entry => {
        return _.get(entry, sourceKey) === value
      })

      let returnResult = entry && _.get(entry, targetKey)
      this.returnResult(returnResult)
      return returnResult
    }

    /*
    * Issue #619
    */
    const get = function(params) {
      let what = params[0]
      let returnResult

      switch (what) {
        case 'user_email':
          returnResult = store.state.user.email
          break
        case 'user_role':
          returnResult = store.state.roleName
          break
        case 'survey_mode':
          // returns 'survey' or 'woenenn'
          returnResult = 'woenenn'
          break
        case 'cati':
          returnResult = false
          break
        default:
          throw new Error(`unknown string "${what}" for custom SurveyJs function get()`)
      }

      return returnResult
    }

    const changeLanguage = function(params) {
      let locale = params[0]

      this.survey.locale = locale

      return locale
    }
    
    const setSurveyOption = function(params) {
      let surveyOptionKey = params[0]
      let surveyOptionValue = params[1]
      this.survey.__OPTIONS = this.survey.__OPTIONS || Object.assign({}, SURVEY_OPTIONS)
      this.survey.__OPTIONS[surveyOptionKey] = surveyOptionValue

      this.survey._dataEntry && this.survey._dataEntry.$forceUpdate()
    }

    // from Issue #951
    const string2number = function(params) {
      if (!params || params.length < 1) {
        return
      }

      let number = Number(params[0])

      if (isNaN(number)) {
        return  
      }
      
      return number
    }

    // from Issue #951
    const abs = function(params) {
      if (!params || params.length < 1) {
        return
      }

      let number = Number(params[0])

      if (isNaN(number)) {
        return
      }

      let absNumber = Math.abs(number)
      
      return absNumber
    }

    FunctionFactory.Instance.register("get", get)
    FunctionFactory.Instance.register("lookup", lookup, true)
    FunctionFactory.Instance.register("changeLanguage", changeLanguage)
    FunctionFactory.Instance.register("setSurveyOption", setSurveyOption)
    FunctionFactory.Instance.register("string2number", string2number)
    FunctionFactory.Instance.register("abs", abs)
  },

  unregisterFunctions() {
    FunctionFactory.Instance.unregister("lookup")
  }
}

export default surveyJsUtil